/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any SCSS you import will output into a single css file
import '../styles/new/new_styles.scss';
// import 'swiper/css';

import TypeIt from 'typeit';
// import Swiper from 'swiper';
import {Gradient} from './components/gradientAnimation';

import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


// Header
try {
    const header = document.querySelector('.header')
    const header_burger = header.querySelector('.burger')
    const header_close = header.querySelector('.close')
    const header_menu = header.querySelector('.menu')

    header_burger.addEventListener('click', () => {
        header_menu.classList.add('active')
        document.body.classList.add('overflow-hidden')
    })

    header_close.addEventListener('click', () => {
        header_menu.classList.remove('active')
        document.body.classList.remove('overflow-hidden')
    })
} catch (err) {
    console.warn(err)
}


// Swiper
/*try {
    Array.from(document.querySelectorAll('.swiper')).forEach(slider => {
        new Swiper(slider, {
            slidesPerView: 'auto',
            spaceBetween: 20,
            breakpoints: {
                992: {
                    spaceBetween: 40
                }
            }
        })
    })
} catch (err) {
    console.warn(err)
}*/


// Typed Code
try {
    Array.from(document.querySelectorAll('.js-type-it-code')).forEach(type_text => {
        new TypeIt(type_text, {
            speed: 100,
            waitUntilVisible: true,
            html: true,
            cursor: true,
            loop: true
        }).pause(2000).go()
    })
} catch (err) {
    console.warn(err)
}


// Typed Text
try {
    Array.from(document.querySelectorAll('.js-type-steps')).forEach(step_container => {
        const type_texts = Array.from(step_container.querySelectorAll('.js-type-it-text'))

        type_texts.forEach(type_text => type_text.classList.add('d-none'))
        const animate_text = (index = 0) => {
            const current_text = type_texts[index]
            const next_index = (index + 1) % type_texts.length

            current_text.classList.remove('d-none')

            new TypeIt(current_text, {
                speed: 50,
                nextStringDelay: 0,
                lifeLike: false,
                waitUntilVisible: true,
                html: true,
                cursor: false
            })
                .pause(5000)
                .go()
                .exec(() => {
                    current_text.classList.add('d-none')
                    animate_text(next_index)
                })
        }

        animate_text()
    })
} catch (err) {
    console.warn(err)
}


// Equal Child heights
try {
    const apply_equal_heights = parent_element => {
        const child_elements = document.querySelectorAll(parent_element.dataset.equalChildHeights)
        if (!child_elements || child_elements.length === 0) return

        child_elements.forEach(child_element => {
            child_element.style.height = 'auto'
        })

        const max_height = Math.max(
            ...Array.from(child_elements).map(child_element => {
                if (child_element.offsetHeight > 0) {
                    return child_element.offsetHeight
                } else {
                    if (child_element.classList.contains('d-none')) {
                        child_element.classList.remove('d-none')
                        const height = child_element.offsetHeight
                        child_element.classList.add('d-none')
                        return height
                    }
                }
            }))
        child_elements.forEach(child_element => {
            child_element.style.height = `${max_height}px`
        })
    }

    const init_equal_heights = () => {
        Array.from(document.querySelectorAll('[data-equal-child-heights]')).forEach(parent_element => {
            apply_equal_heights(parent_element)
        })
    }

    init_equal_heights()

    window.addEventListener('resize', init_equal_heights)

    const resize_observer = new ResizeObserver(() => {
        init_equal_heights()
    })

    Array.from(document.querySelectorAll('[data-equal-child-heights]')).forEach(parent_element => {
        resize_observer.observe(parent_element)
    })
} catch (err) {
    console.warn(err)
}


// Animation Gradients
try {
    Array.from(document.querySelectorAll('.js-gradient-animation')).forEach(animate_gradient => {
        const gradient = new Gradient()
        gradient.initGradient(animate_gradient)
    })
} catch (err) {
    console.warn(err)
}


// Animation Swap Logos
try {
    Array.from(document.querySelectorAll('.js-swap-animate')).forEach(animate_block => {
        const items = animate_block.querySelectorAll('.js-swap-animate-item')

        if (items.length > 1) {
            let current_index = 0
            let adding = true
            let interval

            const start_cycle = () => {
                interval = setInterval(() => {
                    if (adding) {
                        items[current_index].classList.add('active')
                        current_index++

                        if (current_index === items.length) {
                            adding = false
                            current_index = 0

                            clearInterval(interval)
                            setTimeout(start_cycle, 1000)
                        }
                    } else {
                        items[current_index].classList.remove('active')
                        current_index++

                        if (current_index === items.length) {
                            adding = true
                            current_index = 0

                            clearInterval(interval)
                            setTimeout(start_cycle, 1000)
                        }
                    }
                }, 500)
            }

            start_cycle()
        }
    })
} catch (err) {
    console.warn(err)
}


// Add active class if block in view
try {
    Array.from(document.querySelectorAll('[data-block-in-view]')).forEach(block_in_view => {
        const check_visibility = () => {
            const rect = block_in_view.getBoundingClientRect()
            const window_height = window.innerHeight || document.documentElement.clientHeight

            // Get offset from data attribute or default to 0
            const offset = parseInt(block_in_view.getAttribute('data-offset') || '0', 10)

            // Check if the block should only add the active class
            const add_only = block_in_view.hasAttribute('data-add-only')

            // Check if any part of the block is visible in the viewport with offset
            const is_visible =
                (rect.top >= -offset && rect.top <= window_height + offset) || // Top edge is in view with offset
                (rect.bottom >= -offset && rect.bottom <= window_height + offset) || // Bottom edge is in view with offset
                (rect.top < -offset && rect.bottom > window_height + offset) // Block is larger than viewport and covers it partially with offset

            if (is_visible) {
                block_in_view.classList.add('active')
            } else if (!add_only) {
                block_in_view.classList.remove('active')
            }
        }

        check_visibility()

        window.addEventListener('scroll', check_visibility)
    })
} catch (err) {
    console.warn(err)
}


// Horizontal Card Scroller
try {
    Array.from(document.querySelectorAll('.js-horizontal-card-scroller')).forEach(element => {
        const animationWrap = element.querySelector('.animationWrap')
        const containerWrap = element.querySelector('.horizontalWrap')

        const scrollDistance = animationWrap.scrollWidth - containerWrap.offsetWidth

        gsap.to(animationWrap, {
            x: -scrollDistance,
            ease: 'none',
            scrollTrigger: {
                trigger: element,
                start: 'center center',
                end: () => `+=${scrollDistance}px`,
                scrub: true,
                pin: true,
                pinSpacing: true,
                invalidateOnRefresh: true
            }
        })

        ScrollTrigger.refresh()
    })
} catch (err) {
    console.warn(err)
}
